<template>
  <div id="rootDiv">
    <div id="inputDiv">
      <v-text-field solo id="inputContent" v-on:keypress.enter="onInputEnter" v-model="jpContentUpsert.content"
                    label="内容を入力してください"/>
    </div>
    <hr id="hrHoriDivid"/>
    <div id="opeThreeDiv">
      <div id="dataListDiv">
        <div id="dataListInnerDiv">
          <div class="liContentItem" v-for="aContent in jpContents" :key="aContent.id">
            <label id="idLabel">{{ "id: " + aContent.id }}</label>
            <label id="contentLabel">{{ aContent.content }}</label>
          </div>
        </div>
      </div>
      <div id="stateDiv">
        <div id="stateInnerDiv">
          <div id="liStatus" v-for="aMsgObj in statusMsgs" :key="aMsgObj.name">
            <label id="nameStatus">{{ aMsgObj.name }}</label>
            <label id="contentStatus">{{ aMsgObj.content }}</label>
          </div>
        </div>
      </div>
      <div id="operOuter">
        <div id="operInner">
          <div id="deleteAndShowCosmos" class="operRowDiv">
            <v-btn id="btnDelete" v-on:click="deleteWords" class="btnOper">削除項目</v-btn>
            <v-btn id="btnShowCosmosDB" v-on:click="showLatest" class="btnOper">最新項目</v-btn>
          </div>
          <div id="editTxtAndResetAllId" class="operRowDiv">
            <v-btn id="btnEditTxtToEditDB" v-on:click="jpwordsSum" class="btnOper">合計</v-btn>
            <v-btn id="btnResetAllId" v-on:click="resetAllIds" class="btnOper">持ち越す</v-btn>
          </div>
          <div id="password-div">
            <v-text-field class="inputEditPath" v-model="tadalinPwd" onpaste="return false" type="password"
                          oncontextmenu="return false" oncopy="return false"
                          oncut="return false" label="パスワードを入力してください"/>
          </div>
          <v-text-field id="idInput"
                        label="JPWords ID"
                        outlined v-model="jpwordsId"
                        v-on:keypress.enter="onJpwordsInputEnter"
                        type="number"
          ></v-text-field>

          <v-radio-group v-model="reqDest">
            <v-radio :label="'Local'" :value="reqDestConstants.LOCAL" color="#043c78"></v-radio>
            <v-radio :label="'Azure VM'" :value="reqDestConstants.AZURE_VM" color="#043c78"></v-radio>
            <v-radio :label="'Azure VM(Via App Service)'" :value="reqDestConstants.AZURE_VM_VIA_APP_SERVICE"
                     color="#043c78"></v-radio>
            <v-radio :label="'Azure Function'" :value="reqDestConstants.AZURE_FUNCTION_JPWORDS"
                     color="#043c78"></v-radio>
            <v-radio :label="'Azure Function(Via App Service)'"
                     :value="reqDestConstants.AZURE_FUNCTION_JPWORDS_VIA_APP_SERVICE"
                     color="#043c78"></v-radio>
          </v-radio-group>
        </div>

      </div>
    </div>
    <v-overlay :value="isShowProgress">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {buildMaster} from "../httpmaster/HttpMaster"
import {isEmptyOrNull, myLog, isNumber} from "../httpmaster/VariousUtil";
import {
  getReqDestName,
  setReqDestName,
  REQUEST_DEST,
  buildHttpMasterByDest,
  getTimeCostStr,
  getObjByKeyMap, ResponseKeyMap, REQUEST_CTRL
} from "../Commons/Commons";

export default {
  name: "JPWordsManage",
  data() {
    return {
      inputContent: "",
      tadalinPwd: "",
      statusMsgs: [],
      jpContents: [],
      reqIndex: 0,
      OPERATE_MIN_INTERVAL: 2000,
      jpContentUpsert: {id: 0, content: ""},
      reqDest: "",
      reqDestConstants: REQUEST_DEST,
      jpwordsId: "",
      isShowProgress: false,
      inputEnterFlag: true
    }
  },
  created() {
    this.reqDest = getReqDestName();
  },
  watch: {
    reqDest: function () {
      setReqDestName(this.reqDest);
      return this.reqDest;
    },
    jpwordsId: function () {
      this.jpwordsIdChanged();
    },
  },
  methods: {
    onInputEnter() {
      this.upsertJPWords();
    },

    onJpwordsInputEnter() {
      this.jpwordsId = "";
      this.jpContentUpsert.id = 0;
    },

    showLatest() {
      this.showLatestCore();
    },

    jpwordsSum() {
      this.statusMsgs = [];
      this.jpContents = [];
      this.isShowProgress = true;
      buildHttpMasterByDest(REQUEST_CTRL.NIHONNGO, "variousFetch", this.reqDest)
          .addPostRfValue(this.tadalinPwd, true, "data", "tadalinPwd")
          .addPostRfValue("allCount", false, "data", "fetchType")
          .asynPostRestful(body => {
            this.isShowProgress = false;
            let isSuccess = getObjByKeyMap(ResponseKeyMap.IsSuccess, body);
            if (isSuccess) {
              let sumCount = body["response"]["count"];
              this.addStatusMsg("合計:", sumCount);
            }
          }, (body, status, statusTxt) => {
            this.isShowProgress = false;
            this.addStatusMsg("結果:", "リクエストした時にロジックエラーが発生しました！");
            this.addStatusMsg("HttpStatus:", getObjByKeyMap(ResponseKeyMap.HttpStatus, body).toString());
            this.addStatusMsg("原因:", getObjByKeyMap(ResponseKeyMap.Reason, body));
            myLog(statusTxt);
          }, error => {
            this.isShowProgress = false;
            this.addStatusMsg("結果:", "リクエストして失敗しました！");
            this.addStatusMsg("原因:", error);
          });
    },

    resetAllIds() {
    },

    deleteWords() {
      if (isEmptyOrNull(this.jpwordsId)) {
        this.statusMsgs = [];
        this.jpContents = [];
        this.addStatusMsg("警告:", "JPWordsIdは必須です！");
        return;
      }
      this.isShowProgress = true;
      buildHttpMasterByDest(REQUEST_CTRL.NIHONNGO, "deleteWords", this.reqDest)
          .addPostRfValue(this.tadalinPwd, true, "data", "tadalinPwd")
          .addPostRfValue(this.jpContentUpsert, false, "data", "jpContent")
          .asynPostRestful(body => {
            let isSuccess = getObjByKeyMap(ResponseKeyMap.IsSuccess, body);
            this.jpwordsId = "";
            this.jpContentUpsert.content = "";
            this.isShowProgress = false;
            this.statusMsgs = [];
            this.jpContents = [];
            if (isSuccess) {
              let deletedJpContent = body["data"]["deletedJpContent"];
              let sumCount = body["data"]["sumCount"];
              this.addStatusMsg("結果:", "削除するは成功されました！");
              this.addStatusMsg("削除した項目ID:", deletedJpContent.id);
              this.addStatusMsg("削除した項目内容:", deletedJpContent.content);
              this.addStatusMsg("合計:", sumCount);
              this.addStatusMsg("かかり時間:", getTimeCostStr(body, 1));
            } else {
              let isNotThisHaveJpContent = body["data"]["isNotThisHaveJpContent"];
              this.addStatusMsg("結果:", "削除する失敗しました！");
              if (isNotThisHaveJpContent) {
                this.addStatusMsg("原因:", "削除したいIDの項目は存在しません！");
              }
            }
          }, (body, status, statusTxt) => {
            this.statusMsgs = [];
            this.jpContents = [];
            this.isShowProgress = false;
            this.addStatusMsg("結果:", "削除する失敗しました！");
            this.addStatusMsg("HttpStatus:", getObjByKeyMap(ResponseKeyMap.HttpStatus, body).toString());
            this.addStatusMsg("原因:", getObjByKeyMap(ResponseKeyMap.Reason, body));
            myLog(statusTxt);
          }, error => {
            this.statusMsgs = [];
            this.jpContents = [];
            this.isShowProgress = false;
            this.addStatusMsg("結果:", "リクエストして失敗しました！");
            this.addStatusMsg("原因:", error);
          });
    },

    upsertJPWords() {
      if (!this.inputEnterFlag) {
        return;
      }
      if (isEmptyOrNull(this.jpContentUpsert.content)) {
        return;
      }
      this.jpContentUpsert.content = this.jpContentUpsert.content.trim();

      if (!this.isAuthorized()) {
        if (isEmptyOrNull(this.tadalinPwd)) {
          this.statusMsgs = [];
          this.addStatusMsg("警告:", "パスワードを入力してください");
          return;
        }
      }
      this.isShowProgress = true;
      this.inputEnterFlag = false;
      buildHttpMasterByDest(REQUEST_CTRL.NIHONNGO, "upsertWords", this.reqDest)
          .addPostRfValue(this.tadalinPwd, true, "data", "tadalinPwd")
          .addPostRfValue(this.jpContentUpsert, false, "data", "jpContent")
          .asynPostRestful(body => {
            let isSuccess = getObjByKeyMap(ResponseKeyMap.IsSuccess, body);
            this.jpwordsId = "";
            this.jpContentUpsert.content = "";
            this.isShowProgress = false;
            this.statusMsgs = [];
            this.jpContents = [];
            if (isSuccess) {
              let savedFindContent = body["respData"]["jpContent"];
              let sumCount = body["respData"]["sumCount"];
              let formattedIdTimeStr = body["respData"]["formattedIdTimeStr"];


              if (!isEmptyOrNull(formattedIdTimeStr)) {
                this.addStatusMsg("結果:", "保存する成功されました！");
                this.addStatusMsg("Id TimeStr:", decodeURIComponent(formattedIdTimeStr));
              } else {
                this.addStatusMsg("結果:", "更新する成功されました！");
              }
              this.addStatusMsg("合計:", sumCount);
              this.addStatusMsg("かかり時間:", getTimeCostStr(body, 1));
              this.jpContents = [savedFindContent];
            } else {
              let failType = body["respData"]["failType"];
              if (failType === 1) {
                let jpListAlreadyExist = body["respData"]["alreadyIncludeJpWordsList"];
                this.addStatusMsg("結果:", "保存または更新したい単語や慣用句はすでにありました。単語を含めた項目は下記です");
                jpListAlreadyExist.forEach(aContent => {
                  this.addStatusMsg("id: " + aContent.id, aContent.content);
                });
              } else if (failType === 2) {
                this.addStatusMsg("結果:", "更新する失敗しました。");
              }
            }
            this.inputEnterFlag = true;
          }, (body, status, statusTxt) => {
            this.statusMsgs = [];
            this.jpContents = [];
            this.isShowProgress = false;
            this.addStatusMsg("結果:", "保存または更新するは失敗しました！");
            this.addStatusMsg("HttpStatus:", getObjByKeyMap(ResponseKeyMap.HttpStatus, body).toString());
            this.addStatusMsg("原因:", getObjByKeyMap(ResponseKeyMap.Reason, body));
            myLog(statusTxt);
            this.inputEnterFlag = true;
          }, error => {
            this.statusMsgs = [];
            this.jpContents = [];
            this.isShowProgress = false;
            this.addStatusMsg("結果:", "リクエストして失敗しました！");
            this.addStatusMsg("原因:", error);
            this.inputEnterFlag = true;
          });
    },

    showLatestCore() {
      if (!this.isAuthorized()) {
        if (isEmptyOrNull(this.tadalinPwd)) {
          this.statusMsgs = [];
          this.addStatusMsg("警告:", "パスワードを入力してください");
          return;
        }
      }
      let limit = 15;
      if (isNumber(this.jpContentUpsert.content) && parseInt(this.jpContentUpsert.content) > 0) {
        limit = parseInt(this.jpContentUpsert.content);
      }
      this.jpContentUpsert.content = "";
      this.jpwordsId = "";
      this.isShowProgress = true;
      buildHttpMasterByDest(REQUEST_CTRL.NIHONNGO, "findLatestJPContentByLimit", this.reqDest)
          .addPostRfValue("Vue", true, "teriminalEnd")
          .addPostRfValue(limit, false, "reqData", "limit")
          .addPostRfValue(this.tadalinPwd, true, "data", "tadalinPwd")
          .asynPostRestful(body => {
            this.isShowProgress = false;
            let count = body["data"]["count"];
            this.statusMsgs = [];
            if (count > 0) {
              this.addStatusMsg("結果:", "項目リストの取得を成功されました！");
              this.addStatusMsg("総数:", count);
              this.jpContents = body["data"]["jpContentList"];
            } else {
              this.addStatusMsg("結果:", "何も取得できなかった！");
              this.jpContents = [];
            }
          }, (body, status, statusTxt) => {
            this.isShowProgress = false;
            this.statusMsgs = [];
            this.addStatusMsg("結果:", "項目リストの取得は失敗しました！");
            this.addStatusMsg("HttpStatus:", getObjByKeyMap(ResponseKeyMap.HttpStatus, body).toString());
            this.addStatusMsg("原因:", getObjByKeyMap(ResponseKeyMap.Reason, body));
            myLog(statusTxt);
          }, error => {
            this.isShowProgress = false;
            this.statusMsgs = [];
            this.addStatusMsg("結果:", "リクエストして失敗しました！");
            this.addStatusMsg("原因:", error);
          });
    },

    transEditTxtToEditDBAndRewriteEditDB() {
      this.inputContent = "";
      this.statusMsgs = [];
      this.jpContents = [];
      if (isEmptyOrNull(this.editFilePath)) {
        this.statusMsgs = [];
        this.addStatusMsg("警告:", "編集中ファイルのパースを入力してください");
        return;
      }
      let editFilePath = this.editFilePath.trim();
      buildMaster().addPathParas(false, "searchWords", "transEditTxtToEditDBAndRewriteEditDB")
          .addPostRfValue(editFilePath, true, "editingTxtPath")
          .asynPostRestful(body => {
            let count = body["data"]["count"];
            this.addStatusMsg("結果:", "編集テキストから編集DBへの操作が成功されました");
            this.addStatusMsg("かかり時間:", getTimeCostStr(body, 1));
            this.addStatusMsg("編集中DBの項目数:", count);
          }, (body, status, statusTxt) => {
            this.addStatusMsg("結果:", "編集テキストから編集DBへの操作が失敗しました");
            this.addStatusMsg("HttpStatus:", getObjByKeyMap(ResponseKeyMap.HttpStatus, body).toString());
            this.addStatusMsg("原因:", getObjByKeyMap(ResponseKeyMap.Reason, body));
            myLog(statusTxt);
          }, error => {
            this.statusMsgs = [];
            this.addStatusMsg("結果:", "リクエストして失敗しました！");
            this.addStatusMsg("原因:", error);
          });
    },

    jpwordsIdChanged() {
      if (!this.isAuthorized()) {
        if (isEmptyOrNull(this.tadalinPwd)) {
          return;
        }
      }
      this.jpContentUpsert.content = "";
      if (isEmptyOrNull(this.jpwordsId)) {
        this.jpContentUpsert.id = 0;
      } else {
        this.jpContentUpsert.id = parseInt(this.jpwordsId.trim());
      }
      if (isEmptyOrNull(this.jpwordsId)) {
        return;
      }
      this.reqIndex++;
      const localReqIndex = this.reqIndex;

      buildHttpMasterByDest("nihonngo", "searchJPWordsById", this.reqDest)
          .addPostRfValue(this.tadalinPwd, true, "data", "tadalinPwd")
          .addPostRfValue(this.jpContentUpsert.id, true, "reqData", "jpwordsId")
          .asynPostRestful(body => {
            if (localReqIndex === this.reqIndex) {
              let isSuccess = getObjByKeyMap(ResponseKeyMap.IsSuccess, body);
              if (!isEmptyOrNull(isSuccess)) {
                if (isSuccess) {
                  this.jpContentUpsert = body["respData"]["jpcontent"];
                } else {
                  this.jpContentUpsert.content = "";
                }
              } else {
                this.jpContentUpsert.content = "";
              }
            }
          });
    },

    isAuthorized() {
      if (REQUEST_DEST.LOCAL === this.reqDest) {
        return true;
      }
      return false;
    },

    addStatusMsg(name, value) {
      this.statusMsgs.push({name: name, content: value});
    },
  }
}
</script>

<style scoped>
#rootDiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

#inputDiv {
  width: 80%;
  align-self: center;
  margin-top: 15px;
}

#opeThreeDiv {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  height: 100%;
  overflow: hidden;
}

#inputContent {
  margin: 5px 250px;
  font-size: 18px;
  height: 50px;
}

input:focus {
  outline: 1px solid #AFECAB;
}

#hrHoriDivid {
  color: black;
  width: 100%;
}

#dataListDiv {
  display: flex;
  flex-direction: column;
  width: 43%;
  height: 100%;
  overflow: auto;
}

#dataListInnerDiv {
  display: flex;
  flex-direction: column;
}

#stateDiv {
  display: flex;
  flex-direction: column;
  width: 39%;
  height: 100%;
  overflow: auto;
}

#stateInnerDiv {
  display: flex;
  flex-direction: column;
}

#operOuter {
  display: flex;
  flex-direction: column;
  width: 18%;
  margin-right: 19px;
  height: 100%;
  overflow: auto;
}

#operInner {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.operRowDiv {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 60px;
}

#idInput {
  margin-top: 10px;
}

.liContentItem {
  display: flex;
}

#idLabel {
  width: 20%;
  background-color: azure;
  padding-left: 10px;
  font-size: smaller;
}

#contentLabel {
  width: 80%;
}

#liStatus {
  display: flex;
  margin-bottom: 10px;
}

#nameStatus {
  width: 25%;
  background-color: azure;
  padding-right: 8px;
  display: flex;
  justify-content: flex-end;
}

#contentStatus {
  width: 75%;
  display: flex;
  justify-content: flex-start;
  margin-left: 8px;
}

.btnOper {
  width: 45%;
  height: 40px;
}

.inputEditPath {
  margin-left: 10px;
  margin-right: 10px;
}

#password-div {

}

</style>