import {isEmptyOrNull} from "./VariousUtil";

class HttpMaster {
    constructor(baseUrl) {
        this.baseUrlStr = baseUrl;
        this.dataPost = {};
        this.pathParas = [];
        this.reqUrlParas = new Map();
        this.headerParas = {};
    }

    addPostRfValue(value, isEncode) {
        let length = arguments.length;
        if (length <= 2) {
            return this;
        }
        let tempMap = this.dataPost;
        for (let i = 2; i < length; i++) {
            let aArg = arguments[i];
            if (typeof aArg != "string") {
                return this;
            }
            if (!isEmptyOrNull(aArg)) {
                if (i !== length - 1) {
                    let obj = tempMap[aArg];
                    if (!isEmptyOrNull(obj)) {
                        tempMap = obj;
                    } else {
                        let tempMap2 = {};
                        tempMap[aArg] = tempMap2;
                        tempMap = tempMap2;
                    }
                } else {
                    if (typeof value == "string") {
                        if (isEncode) {
                            value = encodeURI(value);
                        }
                    }
                    tempMap[aArg] = value;
                }
            }
        }
        return this;
    }

    addPathParas(isEncode) {
        let lengthArgs = arguments.length;
        if (lengthArgs <= 1) {
            return this;
        }
        for (let i = 1; i < lengthArgs; i++) {
            let aArg = arguments[i];
            if (isEncode) {
                if (typeof aArg == "string") {
                    aArg = encodeURI(aArg);
                }
            }
            this.pathParas.push(aArg);
        }
        return this;
    }

    addUrlRequestParas(key, value, isEncode) {
        if (isEmptyOrNull(key) || typeof key != "string") {
            return this;
        }
        if (isEncode) {
            if (typeof value == "string") {
                value = encodeURI(value);
            }
        }
        this.reqUrlParas.set(key, value);
        return this;
    }

    getPostRfString() {
        return JSON.stringify(this.dataPost);
    }

    getRfRequestUrl() {
        return this.urlStr;
    }

    generateGetOrPostUrl() {
        let urlTemp = this.baseUrlStr;
        if (!isEmptyOrNull(this.pathParas)) {
            this.pathParas.forEach(it => {
                let tempStr = "/" + it;
                urlTemp += tempStr;
            })
        }
        if (!isEmptyOrNull(this.reqUrlParas)) {
            urlTemp += "?";
            this.reqUrlParas.forEach((value, key) => {
                urlTemp += key;
                urlTemp += "=";
                urlTemp += value;
                urlTemp += "&";
            });
            urlTemp = urlTemp.substring(0, urlTemp.length - 1);
        }
        this.urlStr = urlTemp;
        return this.urlStr;
    }

    addHeader(name, value, isEncode) {
        if (isEmptyOrNull(name) || typeof name != "string") {
            return this;
        }
        if (isEmptyOrNull(value) || typeof value != "string") {
            return this;
        }
        if (isEncode) {
            value = encodeURI(value);
        }
        this.headerParas[name] = value;
        return this;
    }

    asynGetRestful(successCallback, logicFailCallback, requestFailCallback) {
        this.addCommonHeader();
        this.generateGetOrPostUrl();
        let status = 0;
        let statusTxt = "";
        fetch(this.urlStr, {
            method: 'GET',
            headers: this.headerParas,
        }).then(response => {
            status = response.status;
            statusTxt = response.statusText;
            return response.json();
        }).then(body => {
            if (status === 200) {
                if (!isEmptyOrNull(successCallback)) {
                    successCallback(body);
                }
            } else {
                if (!isEmptyOrNull(logicFailCallback)) {
                    logicFailCallback(body, status, statusTxt);
                }
            }
        }).catch(error => {
            if (!isEmptyOrNull(requestFailCallback)) {
                requestFailCallback(error);
            }
        });
        return this;
    }

    asynPostRestful(successCallback, logicFailCallback, requestFailCallback) {
        this.addCommonHeader();
        this.generateGetOrPostUrl();
        let status = 0;
        let statusTxt = "";
        fetch(this.urlStr, {
            method: 'POST',
            headers: this.headerParas,
            body: JSON.stringify(this.dataPost)
        }).then(response => {
            status = response.status;
            statusTxt = response.statusText;
            return response.json();
        })
            .then(body => {
                if (status === 200) {
                    if (!isEmptyOrNull(successCallback)) {
                        successCallback(body);
                    }
                } else {
                    if (!isEmptyOrNull(logicFailCallback)) {
                        logicFailCallback(body, status, statusTxt);
                    }
                }
            }).catch(error => {
            if (!isEmptyOrNull(requestFailCallback)) {
                requestFailCallback(error);
            }
        });
        return this;
    }

    addCommonHeader() {
        this.headerParas['Content-Type'] = 'application/json';
    }
}

export let buildMaster = baseUrl => {
    return new HttpMaster(baseUrl);
}
