<template>
  <div id="rootDiv">
    <div id="statesAndInputDiv">
      <p id="statesP">
        項目総数: {{ fileSum }}
      </p>
      <div id="inputSearchDiv">
        <v-text-field v-on:keypress.enter="onInputEnter" id="inputSearch" v-model="keyword"
                      label="ファイル名の一部を入力してください" autocomplete="off"/>
      </div>
      <v-btn v-on:click="selectUploadFile">アップロードファイル選択</v-btn>
      <input type="file" ref="uploadFileEle" @change="onFileChange" v-if="uploadInputFlag" style="display: none"
             multiple/>
      <div id="password-div">
        <v-text-field :type="showPassword ? 'text' : 'password'" class="inputPassword" v-model="tadalinPwd"
                      onpaste="return false"
                      oncontextmenu="return false" oncopy="return false"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      oncut="return false" label="パスワードを入力してください"/>
      </div>

    </div>
    <hr id="hrHoriDivid"/>
    <div id="mainListOutDiv">
      <div id="outComeMsgDiv" v-for="(aMsg, index) in outcomeMsg" :key="index">
        <label id="msgLabel">{{ aMsg }}</label>
      </div>
      <hr id="hrHoriDivid2" v-if="!isEmptyOrNullForThis(outcomeMsg)"/>
      <div id="fileListOutDiv">
        <div id="fileListInnerDiv">
          <div id="fileItemDiv" v-for="(aFile, index) in showFileLists" :key="index">
            <label id="fileNameLabel">{{ aFile["fileName"] }}</label>
            <label id="fileSizeLabel">{{ aFile.fileSizeFormat }}</label>
            <div id="fileBtnDiv">
              <v-btn class="download-delete-btn" v-on:click="onDownloadClick(aFile)">Download</v-btn>
              <v-btn class="download-delete-btn" v-on:click="onDeleteBtn(aFile)">Delete</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="isShowProgress">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {isEmptyOrNull, myLog} from "../httpmaster/VariousUtil";
import {
  BASE_URLS,
  buildHttpMasterByDest, CURRENT_DOMAIN, getObjByKeyMap,
  REQUEST_CTRL,
  REQUEST_DEST, ResponseKeyMap
} from "../Commons/Commons";
import {formatFileSize} from "../Commons/Utils";
// import {TadalinFile} from "../Commons/Entities";

export default {
  data() {
    return {
      fileSum: 0,
      keyword: "",
      tadalinPwd: "",
      fileLists: [],
      showFileLists: [],
      outcomeMsg: [],
      showPassword: false,
      uploadInputFlag: true,
      isShowProgress: true,
      domain: ""
    }
  },
  watch: {
    keyword: function () {
      this.searchFileNameInLocalMemory();
    }
  },
  created() {
    this.domain = document.domain;
    this.fetchFileLists(true);
  },
  methods: {

    isEmptyOrNullForThis(obj) {
      return isEmptyOrNull(obj);
    },
    onInputEnter() {
      if (isEmptyOrNull(this.tadalinPwd)) {
        this.outcomeMsg = [];
        this.outcomeMsg.push("パスワードを入力してください。");
        return;
      }
      this.fetchFileLists(true);
    },
    onFileChange(e) {
      this.uploadFiles(e);
    },
    uploadFiles(e) {
      let formData = new FormData();
      let files = e.target.files;
      if (isEmptyOrNull(files) || files.length === 0) {
        return;
      }
      this.isShowProgress = true;
      this.uploadInputFlag = false;
      formData.append("tadalinPwd", this.tadalinPwd);
      files.forEach(aFile => {
        formData.append("file", aFile);
        formData.append("fileName", encodeURIComponent(aFile.name));
        formData.append("contentType", "file");
      });
      const param = {
        method: "POST",
        body: formData
      };

      let url = BASE_URLS.AZURE_APP_SERVICE + "/trans/tadalinFileUp";
      if (CURRENT_DOMAIN.AZURE_APP_SERVICE !== this.domain) {
        url = BASE_URLS.AZURE_VM + "/tadalinCtrl/tadalinFileUp";
      }

      let status = -1;
      let statusText = "";
      fetch(url, param)
          .then(response => {
            status = response.status;
            statusText = response.statusText;
            return response.json();
          }).then(body => {
        if (status === 200) {
          this.keyword = "";
          this.fetchFileLists(false);
        } else {
          this.isShowProgress = false;
        }
        this.uploadInputFlag = true;
        if (CURRENT_DOMAIN.AZURE_APP_SERVICE === this.domain) {
          this.outcomeMsg = body["data"]["outcomMsg"];
        } else {
          this.onNotAzureAppServiceUpLoadFileOutcome(body, status, statusText);
        }
      }).catch(error => {
        this.outcomeMsg = [];
        this.outcomeMsg.push("アップロードしたときにエラーが発生しました: " + error);
        this.isShowProgress = false;
        this.uploadInputFlag = true;
      });
    },
    onNotAzureAppServiceUpLoadFileOutcome(body, status, statusText) {
      if (status === 200) {
        this.outcomeMsg = [];
        let masterInfoList = body["data"]["masterInfos"];
        let errorMsgs = body["data"]["errorMsgs"];
        let size = isEmptyOrNull(masterInfoList) ? 0 : masterInfoList.length;
        this.outcomeMsg.push("合わせて " + size + " ファイルがアップロード成功しました");
        if (!isEmptyOrNull(masterInfoList)) {
          masterInfoList.forEach(it => {
            this.outcomeMsg.push(decodeURIComponent(it["FileOriginalName"]) + "　" + parseInt(it["size"]) + "(bytes)" + "　" + decodeURIComponent(it["filePathStr"]));
          });
        }
        if (!isEmptyOrNull(errorMsgs)) {
          this.outcomeMsg.push("エラーは下記です。");
          errorMsgs.forEach(it => {
            let errorFileName = it[0];
            let errorReason = it[1];
            this.outcomeMsg.push("Tadalin Server error occurred: " + errorReason + " when upload file: " + errorFileName);
          })
        }
      } else {
        this.outcomeMsg = [];
        this.outcomeMsg.push("リクエスト失敗した、ステータスコード: " + status + " statusText: " + statusText + " body: " + JSON.stringify(body));
      }
    },
    searchFileNameInLocalMemory() {
      this.showFileLists = [];
      if (!isEmptyOrNull(this.keyword)) {
        this.fileLists.forEach(it => {
          if (it["fileName"].includes(this.keyword)) {
            this.showFileLists.push(it);
          }
        });
      } else {
        this.showFileLists = this.fileLists;
      }
      if (!isEmptyOrNull(this.showFileLists)) {
        this.fileSum = this.showFileLists.length;
      } else {
        this.fileSum = 0;
      }
    },
    fetchFileLists(isShowProgress) {
      if (isShowProgress) {
        this.isShowProgress = isShowProgress;
      }
      buildHttpMasterByDest(REQUEST_CTRL.TADALIN_CTRL, "fetchAllFilesInfo", REQUEST_DEST.AZURE_VM_VIA_APP_SERVICE)
          .asynPostRestful(body => {
            this.fileLists = body.data["fileList"];
            this.fileLists.forEach(it => {
              it.fileSizeFormat = formatFileSize(it.fileSize)
            });
            this.isShowProgress = false;
            this.searchFileNameInLocalMemory();
          }, (body, status, statusTxt) => {
            this.outcomeMsg = [];
            this.outcomeMsg.push("ファイル取得する失敗した")
            this.outcomeMsg.push("HttpStatus: " + body["response"]["HttpStatus"] + " " + status + " " + statusTxt);
            this.outcomeMsg.push("Reason: " + body["response"]["reason"]);
            this.isShowProgress = false;
          }, error => {
            this.outcomeMsg = [];
            this.outcomeMsg.push("ファイル取得するAPIをリクエスト失敗した")
            this.outcomeMsg.push("error: " + error);
            this.isShowProgress = false;
          });
    },
    selectUploadFile() {
      if (isEmptyOrNull(this.tadalinPwd)) {
        this.outcomeMsg = [];
        this.outcomeMsg.push("パスワードを入力してください。");
        return;
      }
      this.$refs.uploadFileEle.click();
    },
    onDeleteBtn(aFile) {
      if (isEmptyOrNull(this.tadalinPwd)) {
        this.outcomeMsg = [];
        this.outcomeMsg.push("パスワードを入力してください。");
        return;
      }
      this.isShowProgress = true;
      buildHttpMasterByDest(REQUEST_CTRL.TADALIN_CTRL, "tadalinFileDel", REQUEST_DEST.AZURE_VM_VIA_APP_SERVICE)
          .addPostRfValue(this.tadalinPwd, true, "data", "tadalinPwd")
          .addPostRfValue(aFile, false, "data", "deleteFile")
          .asynPostRestful(body => {
            let result = body["data"]["result"];
            let retFile = body["data"]["deletedFile"];
            this.outcomeMsg = [];
            if (result) {
              this.outcomeMsg.push("ファイルを削除成功されました");
              this.fetchFileLists(false);
            } else {
              this.outcomeMsg.push("ファイルを削除失敗した");
              this.isShowProgress = false;
            }
            this.outcomeMsg.push(retFile["fileName"] + "　" + retFile["filePath"]);

          }, (body, status, statusTxt) => {
            this.outcomeMsg = [];
            this.outcomeMsg.push("Logic Fail ファイルを策上失敗した HttpStatus: " + getObjByKeyMap(ResponseKeyMap.HttpStatus, body).toString());
            this.outcomeMsg.push("原因: " + getObjByKeyMap(ResponseKeyMap.Reason, body));
            this.isShowProgress = false;
            myLog(statusTxt);
          }, error => {
            this.outcomeMsg = [];
            this.outcomeMsg.push("ファイルを削除失敗した");
            this.outcomeMsg.push("原因: " + error);
            this.isShowProgress = false;
          });
    },
    onDownloadClick(aFile) {
      if (isEmptyOrNull(this.tadalinPwd)) {
        this.outcomeMsg = [];
        this.outcomeMsg.push("パスワードを入力してください。");
        return;
      }
      let url = BASE_URLS.AZURE_APP_SERVICE + "/trans/tadalinDownFile/" + this.tadalinPwd + "/" + aFile["fileName"];
      if (CURRENT_DOMAIN.AZURE_APP_SERVICE !== this.domain) {
        url = BASE_URLS.AZURE_VM + "/tadalinCtrl/tadalinDownFile/" + this.tadalinPwd + "/" + aFile["fileName"];
      }
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
    },
    testProgress(context) {
      let waitProgress = new Promise(function (resolve, reject) {
        setTimeout(() => {
          context.isShowProgress = false;
          if (!context.isShowProgress) {
            resolve("completed");
          } else {
            reject("not completed");
          }
        }, 3000);
      });
      waitProgress.then(
          success => console.log("progress " + success),
          reason => console.log("fail" + reason)
      );
    }
  },

  name: "TadalinVMFile"
}
</script>

<style scoped>
#rootDiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

#statesAndInputDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
}

#inputSearchDiv {
  width: 360px;
}

#inputSearch {
  font-size: medium;
  height: 37px;
}

#statesP {
}

#password-div {
  width: 270px;
}

.inputPassword {
  font-size: medium;
}

#hrHoriDivid {
  color: black;
  width: 100%;
}

#hrHoriDivid2 {
  color: black;
  width: 100%;
}

#mainListOutDiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

#outComeMsgDiv {
  display: flex;
}

#fileListOutDiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

#fileListInnerDiv {
  display: flex;
  flex-direction: column;
}

#fileItemDiv {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  height: 56px;
  background-color: #AFECAB;
}

#msgLabel {
  background-color: azure;
  padding-left: 15px;
  margin-top: 2px;
  width: 100%;
}

#fileNameLabel {
  width: 70%;
  padding-left: 15px;
}

#fileSizeLabel {
  width: 15%;
}

#fileBtnDiv {
  display: flex;
  justify-content: space-evenly;
  width: 15%;
}

.download-delete-btn {
  width: 100px;
  height: 50px;
}
</style>