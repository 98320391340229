<template>
  <div id="root">
    <div id="statesAndInputDiv">
      <div id="state-div">
        項目総数: {{ size }}
        <br>{{ timeCost }} をかかります
        <br>実際キーワード: {{ jisaiKeyword }}
      </div>

      <div id="inputSearch-div">
        <v-text-field solo v-on:keypress.enter="onInputEnter" class="inputSearch" v-model="keyword"
                      :maxlength="maxLength"
                      label="単語や慣用句を入力してください"/>
      </div>

      <div id="password-div">
        <div id="password-inner-div">
          <v-text-field :type="'password'" class="inputPassword" v-model="tadalinPwd" onpaste="return false"
                        oncontextmenu="return false" oncopy="return false"
                        oncut="return false" label="パスワードを入力してください"/>
        </div>
      </div>

    </div>
    <div id="resultDiv">
      <ul id="jpcontent-results">
        <li id="liAContent" v-for="(aContent, index) in jpContents" :key="aContent.id"
            v-on:click="itemClick(aContent.id)">
          <label id="idLabel">{{ (index + 1) + "." }}</label>
          <div class="contentLblDiv" v-ripple>
            <label id="contentLabel">{{ aContent.content }}</label>
          </div>
        </li>
      </ul>
    </div>

    <v-snackbar
        v-model="idSnackbarIsShow"
        :timeout="idSnackBarTO"
    >
      {{ "Id: " + clickJpwordsId }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="idSnackbarIsShow = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {isEmptyOrNull, myLog, transZennkakuAlphaNumeric} from "../httpmaster/VariousUtil";
import {
  buildHttpMasterByDest, getObjByKeyMap,
  getReqDestName,
  getTimeCostStr, REQUEST_CTRL, ResponseKeyMap
} from "../Commons/Commons";

export default {
  data() {
    return {
      keyword: "",
      tadalinPwd: "",
      transFormedKeyword: "",
      lastTransFormedKeyword: "",
      maxLength: 28,
      jisaiKeyword: "",
      jpContents: [],
      timeCost: "0 millisecond",
      reqIndex: 0,
      autoEraseIndex: 0,
      eraseTime: 2000,
      reqDest: "",
      size: 0,
      idSnackbarIsShow: false,
      idSnackBarTO: 2000,
      clickJpwordsId: ""
    }
  },
  watch: {
    keyword: function () {
      this.searchKeywords();
      this.autoErase();
    },
  },
  created() {
    this.reqDest = getReqDestName();
  },
  updated() {
    // this.searchKeywords();
  },
  methods: {
    searchKeywords() {
      this.transFormedKeyword = transZennkakuAlphaNumeric(this.keyword, false);
      if (isEmptyOrNull(this.transFormedKeyword)) {
        return;
      }
      if (this.transFormedKeyword.normalize() !== this.lastTransFormedKeyword.normalize()) {
        this.lastTransFormedKeyword = this.transFormedKeyword;
        this.jpWordsSearchCore(this.transFormedKeyword);
      }
    },

    jpWordsSearchCore(inputKeyword) {
      this.reqIndex++;
      const localReqIndex = this.reqIndex;
      this.size = 0;
      this.timeCost = "0 millisecond";
      this.jisaiKeyword = "";

      buildHttpMasterByDest(REQUEST_CTRL.NIHONNGO, "searchJPWords", this.reqDest)
          .addPostRfValue("vue側", true, "data", "device")
          .addPostRfValue(inputKeyword, true, "data", "keyword")
          .addPostRfValue(this.tadalinPwd, true, "data", "tadalinPwd")
          .asynPostRestful(body => {
            if (localReqIndex === this.reqIndex) {
              this.jpContents = body.data.result;
              if (!isEmptyOrNull(this.jpContents)) {
                this.size = this.jpContents.length;
              }
              this.timeCost = getTimeCostStr(body);

              let jisaikeywordOrigin = body["data"]["lastSearchKeyword"];
              jisaikeywordOrigin = jisaikeywordOrigin.replaceAll("+","%20");
              this.jisaiKeyword = decodeURI(jisaikeywordOrigin);
            }
          }, (body, status, statusText) => {
            myLog("logicFail: " + body["reason"] + " status: " + status + " statusText: " + statusText + " reason: " + getObjByKeyMap(ResponseKeyMap.Reason, body));
          }, error => {
            myLog("requestFail: " + error);
          });
    },

    autoErase() {
      this.autoEraseIndex++;
      let localIndex = this.autoEraseIndex;
      new Promise(resolve => {
        setTimeout(() => {
          if (localIndex === this.autoEraseIndex) {
            resolve();
          }
        }, this.eraseTime);
      }).then(
          () => {
            this.keyword = "";
          }
      );
    },

    itemClick(jpwordsId) {
      this.idSnackbarIsShow = true;
      this.clickJpwordsId = jpwordsId;
    },

    onInputEnter() {
      this.keyword = "";
    },

    inputKeyStroke($event) {
      this.keyword = $event.target.value;
      this.searchKeywords();
      this.autoErase();
      // console.log("start value: " + $event.target.value);
    },
  }
}
</script>

<style scoped>

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.inputSearch {
  font-size: large;
}

#resultDiv {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 10px;
  height: 100%;
}

.inputPassword {
}

#inputSearch-div {
  width: 35%;
}

#password-inner-div {
  width: 50%;
}

#password-div {
  display: flex;
  justify-content: center;
  width: 25%;
}

#state-div {
  width: 25%;
}

#jpcontent-results {
}

#liAContent {
  display: flex;
  list-style-type: none;
}

#statesAndInputDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

#idLabel {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  width: 15%;
}

#contentLabel {
  cursor: pointer;
}

.contentLblDiv {
  width: 85%;
  margin-right: 10px;
}

input:focus {
  outline: 1px solid #AFECAB;
}
</style>