import {isEmptyOrNull} from "../httpmaster/VariousUtil";

export let formatFileSize = sizeNum => {
    if (isEmptyOrNull(sizeNum)) {
        return "0 Bytes";
    }
    let unitArr = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let index = 0;
    let srcsize = parseFloat(sizeNum);
    index = Math.floor(Math.log(srcsize) / Math.log(1024));
    let size = srcsize / Math.pow(1024, index);
    size = size.toFixed(2);
    return size + unitArr[index];
}