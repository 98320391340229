import Vue from 'vue'
import JPWordsSearch from './views/JPWordsSearch';
import JPWordsManage from "./views/JPWordsManage";
import TadalinVMFiles from "./views/TadalinVMFiles";
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

const routes = {
  '/': JPWordsSearch,
  '/control-c': JPWordsManage,
  '/files': TadalinVMFiles
}

new Vue({
  data: {
    currentRoute: window.location.pathname
  },

  computed: {
    ViewComponent() {
      return routes[this.currentRoute];
    }
  },

  vuetify,
  render (h) { return h(this.ViewComponent) }
}).$mount('#app')

