import {isEmptyOrNull} from "../httpmaster/VariousUtil";
import {buildMaster} from "../httpmaster/HttpMaster";

export const BASE_URLS = {
    LOCAL: "http://192.168.1.101",
    AZURE_APP_SERVICE: "https://tadalin.azurewebsites.net", //azure function is via Azure App Service indirect request
    AZURE_FUNCTION: "https://tadalinfuncts.azurewebsites.net/api",
    AZURE_VM: "http://tadalin.japaneast.cloudapp.azure.com",
    DEV: "http://localhost:55"
};
export const REQUEST_DEST = {
    LOCAL: "LOCAL",
    AZURE_FUNCTION_JPWORDS_VIA_APP_SERVICE: "AZURE_FUNCTION_JPWORDS_VIA_APP_SERVICE",
    AZURE_FUNCTION_JPWORDS: "AZURE_FUNCTION_JPWORDS",
    AZURE_FUNCTION_VARIOUS_HTTP_VIA_APP_SERVICE: "AZURE_FUNCTION_VARIOUS_HTTP_VIA_APP_SERVICE",
    AZURE_FUNCTION_VARIOUS_HTTP: "AZURE_FUNCTION_VARIOUS_HTTP",
    AZURE_VM_VIA_APP_SERVICE: "AZURE_VM_VIA_APP_SERVICE",
    AZURE_VM: "AZURE_VM"
};
export const CURRENT_DOMAIN = {
    AZURE_APP_SERVICE: "tadalin.azurewebsites.net"
};

export const REQUEST_CTRL = {
    NIHONNGO: "nihonngo",
    TADALIN_CTRL: "tadalinCtrl"
};

export const ResponseKeyMap = {
    HttpStatus: ["response", "HttpStatus"],
    Reason: ["response", "reason"],
    TimeCost: ["response", "timeCost"],
    IsSuccess: ["response", "isSuccess"]
};
export const REQUEST_DEST_STORE_KEY = "tadalin_req_dest";

export let getObjByKeyMap = (keymap, obj) => {
    let tempObj = obj;
    if (!isEmptyOrNull(keymap)) {
        keymap.forEach(e => {
            tempObj = tempObj[e];
        });
    }
    return tempObj;
}

export let getTimeCostStr = (body, type) => {
    let timeCostArr = getObjByKeyMap(ResponseKeyMap.TimeCost, body);
    let resultStr = "";
    if (timeCostArr[0] === 0) {
        if (isEmptyOrNull(type)) {
            resultStr = timeCostArr[1] + " milliseconds";
        } else {
            resultStr = timeCostArr[1] + " (milliseconds)";
        }

    } else {
        if (isEmptyOrNull(type)) {
            resultStr = timeCostArr[1] + " nanoseconds";
        } else {
            resultStr = timeCostArr[1] + " (nanoseconds)";
        }
    }
    return resultStr;
}

export let getReqDestName = () => {
    let value = localStorage.getItem(REQUEST_DEST_STORE_KEY);
    if (isEmptyOrNull(value)) {
        return REQUEST_DEST.AZURE_FUNCTION_JPWORDS_VIA_APP_SERVICE;
    }
    return value;
}

export let setReqDestName = destName => {
    localStorage.setItem(REQUEST_DEST_STORE_KEY, destName);
}

export let getBaseUrl = (destName) => {
    let baseUrl = "";
    switch (destName) {
        case REQUEST_DEST.LOCAL:
            baseUrl = BASE_URLS.LOCAL;
            break;
        case REQUEST_DEST.AZURE_FUNCTION_JPWORDS_VIA_APP_SERVICE:
        case REQUEST_DEST.AZURE_FUNCTION_VARIOUS_HTTP_VIA_APP_SERVICE:
        case REQUEST_DEST.AZURE_VM_VIA_APP_SERVICE:
            baseUrl = BASE_URLS.AZURE_APP_SERVICE;
            break;
        case REQUEST_DEST.AZURE_FUNCTION_JPWORDS:
        case REQUEST_DEST.AZURE_FUNCTION_VARIOUS_HTTP:
            baseUrl = BASE_URLS.AZURE_FUNCTION;
            break;
        case REQUEST_DEST.AZURE_VM:
            baseUrl = BASE_URLS.AZURE_VM;
            break;
    }
    return baseUrl;
}

export let buildHttpMasterByDest = (ctrlName, actionName, destName) => {
    let master = buildMaster(getBaseUrl(destName));
    if (destName === REQUEST_DEST.LOCAL) {
        master.addPathParas(false, ctrlName, actionName);
    } else if (destName === REQUEST_DEST.AZURE_VM_VIA_APP_SERVICE) {
        master.addPathParas(false, "trans/postTrans")
            .addPostRfValue(REQUEST_DEST.AZURE_VM_VIA_APP_SERVICE, true, "request", "data", "tadalinDest")
            .addPostRfValue(ctrlName + "/" + actionName, true, "request", "data", "reqPath");
    } else if (destName === REQUEST_DEST.AZURE_VM) {
        master.addPathParas(false, ctrlName, actionName);
    } else if (destName === REQUEST_DEST.AZURE_FUNCTION_JPWORDS_VIA_APP_SERVICE) {
        master.addPathParas(false, "trans/postTrans")
            .addPostRfValue(REQUEST_DEST.AZURE_FUNCTION_JPWORDS_VIA_APP_SERVICE, true, "request", "data", "tadalinDest")
            .addPostRfValue(ctrlName + "/" + actionName, true, "request", "data", "reqPath");
    } else if (destName === REQUEST_DEST.AZURE_FUNCTION_JPWORDS) {
        master.addPathParas(false, "JPwords")
            .addPostRfValue(ctrlName + "/" + actionName, true, "request", "data", "reqPath");
    } else if (destName === REQUEST_DEST.AZURE_FUNCTION_VARIOUS_HTTP_VIA_APP_SERVICE) {
        master.addPathParas(false, "trans/postTrans")
            .addPostRfValue(REQUEST_DEST.AZURE_FUNCTION_VARIOUS_HTTP_VIA_APP_SERVICE, true, "request", "data", "tadalinDest")
            .addPostRfValue(ctrlName, true, "ctrlName")
            .addPostRfValue(actionName, true, "methodName");
    } else if (destName === REQUEST_DEST.AZURE_FUNCTION_VARIOUS_HTTP) {
        master.addPathParas(false, "VariousHttp")
            .addUrlRequestParas("code", "UBhlJvKe/xudMdRtOQempAoiJjzAcg//B8VVK02U4WeADtRKzf/Msw==", false)
            .addPostRfValue(ctrlName, true, "ctrlName")
            .addPostRfValue(actionName, true, "methodName");
    }
    return master;
}

