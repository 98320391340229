export let isEmptyOrNull = obj => {
    if (obj === undefined) {
        return true;
    }
    if (obj == null) {
        return true;
    }
    if (typeof obj == "string") {
        return obj.trim().length === 0;
    }
    if (typeof obj == "object") {
        if (Array.isArray(obj)) {
            return obj.length === 0;
        }
        if (obj instanceof Map) {
            return obj.size === 0;
        }
        if (obj instanceof Set) {
            return obj.size === 0;
        }
    }
    return false;
}

/**
 * if isEmptyOrNull == true, return false
 * @param input
 * @returns {boolean}
 */
export let isNumber = input => {
    if (isEmptyOrNull(input)) {
        return false;
    }
    return !isNaN(input);
}

/**
 * 全角英数字から空文字または半角に変換
 * @param input will have isEmptyOrNull and trim operation
 * @param isTansToHannkaku true-半角に変換、false-空文字に書き換え
 * @returns {*}
 */
export let transZennkakuAlphaNumeric = (input, isTansToHannkaku) => {
    if (isEmptyOrNull(input)) {
        return "";
    }
    let result = "";
    if (isTansToHannkaku) {
        result = input.trim().replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    } else {
        result = input.trim().replace(/[Ａ-Ｚａ-ｚ０-９]/g, "");
    }
    return result;
}

export let myLog = message => {
    console.log(message);
}